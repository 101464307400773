import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from '../pages/Loading';
import { Suspense } from 'react';

const LandingPage = lazy(() => import("../pages/landing/Landing.jsx"));
const PlatformPage = lazy(() => import("../pages/Platform"));
// const DashboardPage = lazy(() => import("../pages/Dashboard.jsx"));
const LoginPage = lazy(() => import("../pages/Login"));
const DesignPage = lazy(() => import("../pages/design/Design"));
const Myplatformpage  = lazy(() => import("../pages/Myplatform.jsx"));
const Garage = lazy(() => import("../pages/garage/Garage.jsx"));
const CheckoutPage = lazy(() => import("../pages/Checkout.jsx"));
const AddNewPProduct = lazy(() => import("../pages/addProductCard.jsx"));
const ProductList = lazy(() => import("../pages/productList.jsx"));
const DashboardAdminPage = lazy(() => import("../pages/dashboard_admin.jsx"));
const DashboardInstallerPage = lazy(() => import("../pages/Dashboard_installer.jsx"));
const RoleBasedDashboard = lazy(() => import("../pages/RoleBasedDashboard.jsx"));
const UserProfilePage = lazy(() => import("../pages/UserProfile.jsx"));
const UsersFather = lazy(() => import("../pages/UsersFather.jsx"));
const ProductsFather = lazy(() => import("../pages/ProductsFather.jsx"));
const OrdersPage = lazy(() => import("../pages/Orders.jsx"));
const Dashdesigner = lazy(() => import("../pages/Dashboard_designer.jsx"));
export const AppRouter = () => {

    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} /> 
                <Route path="/platform" element={<PlatformPage />} />
                <Route path="/garage" element={<Garage />} />
                <Route path="/dashboard" element={<RoleBasedDashboard />} />
                 <Route path="/dashboard_admin" element={<DashboardAdminPage />} />
                 <Route path="/dashboard_designer" element={<Dashdesigner/>}/>
                <Route path="/dashboard_installer" element={<DashboardInstallerPage />} /> 
                <Route path="/design" element={<DesignPage />} />
                <Route path="/Myplatform" element={<Myplatformpage />} />
                <Route path="/Checkout" element={<CheckoutPage />} />
                <Route path="/Add" element={<AddNewPProduct/>} />
                <Route path="/ProductList" element={<ProductList/>} />
                <Route path="/Profile" element={<UserProfilePage />} />
                <Route path="/Users" element={<UsersFather />} />
                <Route path="/Products" element={<ProductsFather />} />
                <Route path="/Orders" element={<OrdersPage />} />
            </Routes>
        </Suspense>
    )

};